import React from "react";

export function CheckRingSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66683 8C2.66683 5.05448 5.05464 2.66666 8.00016 2.66666C10.9457 2.66666 13.3335 5.05448 13.3335 8C13.3335 10.9455 10.9457 13.3333 8.00016 13.3333C5.05464 13.3333 2.66683 10.9455 2.66683 8ZM8.00016 1.33333C4.31826 1.33333 1.3335 4.3181 1.3335 8C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8C14.6668 4.3181 11.6821 1.33333 8.00016 1.33333ZM10.1547 5.90656C10.3904 5.6237 10.8108 5.58549 11.0936 5.8212C11.3765 6.05691 11.4147 6.47728 11.179 6.76013L8.78032 9.6385L8.75568 9.66808C8.55862 9.90464 8.36906 10.1322 8.18837 10.2966C7.98596 10.4807 7.71537 10.6611 7.3491 10.6777C6.98283 10.6943 6.69701 10.5391 6.47877 10.3741C6.28394 10.2268 6.07455 10.0173 5.85688 9.79956L5.82967 9.77234L4.86207 8.80475C4.60173 8.5444 4.60173 8.12229 4.86207 7.86194C5.12242 7.60159 5.54453 7.60159 5.80488 7.86194L6.77248 8.82953C7.02778 9.08484 7.1711 9.22601 7.28296 9.31059L7.28723 9.31381L7.2912 9.31022C7.39494 9.21586 7.52488 9.06229 7.75603 8.78492L10.1547 5.90656Z"
        fill="#009639"
      />
    </svg>
  );
}
