import { memo, ReactElement } from "react";
import cn from "classnames";
import styles from "./DashboardLayout.module.scss";

interface LeftSidebarLayoutProps {
  LeftContainer: ReactElement;
  isLeftSidebarVisible: boolean;
  leftSidebarClasses: string[];
}

function LeftSidebarLayout({
  LeftContainer,
  isLeftSidebarVisible,
  leftSidebarClasses,
}: LeftSidebarLayoutProps) {
  return (
    <>
      {isLeftSidebarVisible ? (
        <div className={cn(leftSidebarClasses)}>
          <div className={styles.scrollingElement}>{LeftContainer}</div>
        </div>
      ) : <></>}
    </>
  );
}

export default memo(LeftSidebarLayout);
