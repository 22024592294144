import React, { memo, ReactElement, useMemo } from "react";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import cn from "classnames";
import { BaseButton } from "@/src/component/base/BaseButton";
import { DragBar } from "@/src/component/base/DragBar";
import { DraggablePaper } from "@/src/component/base/DraggablePaper";
import { KEYS } from "@/src/const/keys";
import { useDragToClose } from "@/src/hook/useDragToClose";
import { DashboardMobileDialogContentTypes } from "@/src/type/blaceV2";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import styles from "./DashboardLayout.module.scss";

interface MainContainerLayoutProps {
  isMobile: boolean;
  isDialogMobileOpen: boolean;
  isMainContainerVisible: boolean;
  mainContainerClasses: string[];
  dialogContentType: DashboardMobileDialogContentTypes;
  MainContainer: ReactElement;
  RightContainer: ReactElement;
  DrawerFAQContainer: ReactElement;
  DashboardMobileManager: ReactElement;
  onRightSidebarHandlerOpen: () => void;
  onMobileDialogManagerOpen: () => void;
  onMobileDialogManagerClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MainContainerLayout({
  isMobile,
  isMainContainerVisible,
  isDialogMobileOpen,
  mainContainerClasses,
  dialogContentType,
  onRightSidebarHandlerOpen,
  onMobileDialogManagerOpen,
  onMobileDialogManagerClose,
  MainContainer,
  RightContainer,
  DashboardMobileManager,
  DrawerFAQContainer,
}: MainContainerLayoutProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const showDragBar: boolean = dialogContentType !== DashboardMobileDialogContentTypes.RenderMobileDialogManager;
  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: onMobileDialogManagerClose,
  });

  const dialogContent = useMemo(() => {
    switch (dialogContentType) {
      case DashboardMobileDialogContentTypes.RenderRightSidebarContainer:
        return RightContainer;
      case DashboardMobileDialogContentTypes.RenderMobileDialogManager:
        return DashboardMobileManager;
      case DashboardMobileDialogContentTypes.RenderFAQContainer:
        return DrawerFAQContainer;
      default:
        return <></>;
    }
  }, [
    DrawerFAQContainer,
    DashboardMobileManager,
    RightContainer,
    dialogContentType,
  ]);

  return (
    <>
      {isMainContainerVisible && (
        <div className={cn(mainContainerClasses)}>
          {isMobile && (
            <div className={styles.switchButtonsList}>
              <BaseButton
                className={styles.switchButton}
                onClick={onRightSidebarHandlerOpen}
              >
                <img
                  src={`${SVG_URL}/infoIcon.svg`}
                  height="40"
                  width="40"
                  alt="Info icon"
                />
              </BaseButton>
              <BaseButton
                className={styles.switchButton}
                onClick={onMobileDialogManagerOpen}
              >
                <img
                  src={`${SVG_URL}/burgerIcon.svg`}
                  height="40"
                  width="40"
                  alt="Burger icon"
                />
              </BaseButton>
            </div>
          )}
          {MainContainer}
          {(isMobile && isDialogMobileOpen) && (
            <Dialog
              sx={{
                "& .MuiDialog-container": {
                  alignItems: "flex-end",
                },
                "& .MuiDialog-paper": {
                  maxWidth: "100%",
                  width: "100%",
                  margin: "0",
                },
              }}
              open={true}
              TransitionComponent={Transition}
              keepMounted
              PaperComponent={props => (
                <DraggablePaper
                  handleStop={handleDragStop}
                  handleDrag={handleDrag}
                  dragId={dragId}
                  {...props}
                />
              )}
              onClose={onMobileDialogManagerClose}
              aria-describedby="alert-dialog-slide-description"
            >
              {showDragBar && <DragBar dragId={dragId} />}
              {dialogContent}
            </Dialog>
          )}
        </div>
      )}
    </>
  );
}

export default memo(MainContainerLayout);
