import React, { PropsWithChildren } from "react";
import styles from "./TabPanel.module.scss";

interface TabPanelProps {
  tabName: string;
  openTabName: string;
}

function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const { children, openTabName, tabName, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={openTabName !== tabName}
      id={`full-width-tabpanel-${tabName}`}
      aria-labelledby={`full-width-tab-${tabName}`}
      {...other}
      className={styles.tabPanelWrapper}
    >
      {openTabName === tabName && <>{children}</>}
    </div>
  );
}

export default TabPanel;
