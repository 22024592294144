import cn from "classnames";
import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  totalPayments: number;
  paymentsCompleted: number;
}

const ProgressBar = ({
  totalPayments,
  paymentsCompleted,
}: ProgressBarProps) => {
  const sections = Array.from({ length: totalPayments }, (_, index) => {
    const isCompleted = index < paymentsCompleted;
    const next = index === paymentsCompleted;
    const isFirstSection = index === 0;
    const isLastSection = index === totalPayments - 1;
    const sectionClasses = cn(styles.progressSection, {
      [styles.completed]: isCompleted,
      [styles.next]: next,
      [styles.roundedOnLeft]: isFirstSection,
      [styles.roundedOnRight]: isLastSection,
    });

    return <div key={index} className={sectionClasses}></div>;
  });

  const progressBarClasses = cn(styles.progressBar, {
    [styles.notCompleted]: paymentsCompleted === 0,
  });

  return (
    <div className={progressBarClasses}>
      {paymentsCompleted !== 0 && sections}
    </div>
  );
};

export default ProgressBar;
