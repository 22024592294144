import { memo, ReactElement } from "react";
import cn from "classnames";
import { ExitButton } from "@/src/component/base/ExitButton";
import styles from "./DashboardLayout.module.scss";

interface FAQLayoutProps {
  isFaqContainerVisible: boolean;
  drawerFAQContainerClasses: string[];
  onFAQSectionHandlerClose: () => void;
  DrawerFAQContainer: ReactElement;
}

function FAQLayout({
  isFaqContainerVisible,
  drawerFAQContainerClasses,
  onFAQSectionHandlerClose,
  DrawerFAQContainer,
}: FAQLayoutProps) {
  return (
    <>
      {isFaqContainerVisible ? (
        <div className={cn(drawerFAQContainerClasses)}>
          <ExitButton
            onExit={onFAQSectionHandlerClose}
            className={styles.buttonExit}
          />
          {DrawerFAQContainer}
        </div>
      ) : <></>}
    </>
  );
}

export default memo(FAQLayout);
