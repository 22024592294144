import { memo, ReactElement } from "react";
import cn from "classnames";
import { BaseButton } from "@/src/component/base/BaseButton";
import styles from "./DashboardLayout.module.scss";

interface RightSidebarLayoutProps {
  isDesktop: boolean;
  isRightSidebarVisible: boolean;
  rightSidebarClasses: string[];
  RightContainer: ReactElement;
  onFAQSectionHandlerOpen?: () => void;
  onClickContactSupport: () => void;
}

//TODO: these needs to become more generic

function RightSidebarLayout({
  isDesktop,
  rightSidebarClasses,
  isRightSidebarVisible,
  RightContainer,
  onFAQSectionHandlerOpen,
  onClickContactSupport
}: RightSidebarLayoutProps) {
  return (
    <>
      {isRightSidebarVisible && (
        <div className={cn(rightSidebarClasses)}>
          {RightContainer}
          {isDesktop && (
            <div className={styles.buttonsContainer}>
              {typeof onFAQSectionHandlerOpen === "function" && (
                <>
                  <BaseButton
                    className={styles.faqButton}
                    onClick={onFAQSectionHandlerOpen}
                  >
                    FAQ
                  </BaseButton>
                </>
              )}
              <BaseButton
                className={styles.faqButton}
                onClick={onClickContactSupport}
              >
                Contact Support
              </BaseButton>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default memo(RightSidebarLayout);
