import { PropsWithChildren } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import styles from "./RightSidebarAccordion.module.scss";

interface RightSidebarAccordionProps {
  isExpanded: boolean;
  onChange: (id: string) => void;
  title: string;
  id: string;
}

function RightSidebarAccordion({
  isExpanded,
  onChange,
  children,
  title,
  id,
}: PropsWithChildren<RightSidebarAccordionProps>) {
  return (
    <Accordion
      disableGutters
      expanded={isExpanded}
      onChange={() => onChange(id)}
      className={styles.rightSidebarAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        className={styles.accordionSummary}
      >
        <Typography variant="subtitle1" className={styles.panelTitle}>
          {title}
        </Typography>
      </AccordionSummary>
      {children}
    </Accordion>
  );
}

export default RightSidebarAccordion;
