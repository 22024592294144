import type { Dispatch, ReactElement, SetStateAction } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useBreakPointDown, useBreakPointUp } from "@/src/hook/style";
import {
  DashboardMobileDialogContentTypes,
  InquirySearchType,
} from "@/src/type/blaceV2";
import styles from "./DashboardLayout.module.scss";
import FAQLayout from "./FAQLayout";
import LeftSidebarLayout from "./LeftSidebarLayout";
import MainContainerLayout from "./MainContainerLayout";
import RightSidebarLayout from "./RightSidebarLayout";

interface DashboardLayoutProps {
  isFAQMobileOpen: boolean;
  isFAQDesktopOpen: boolean;
  isInquirySelected: boolean;
  isLeftContainerCollapsed: boolean;
  isRightContainerCollapsed: boolean;
  showFAQButton?: boolean;
  LeftContainer: ReactElement;
  MainContainer: ReactElement;
  RightContainer: ReactElement;
  DrawerFAQContainer: ReactElement;
  DashboardMobileManager: ReactElement;
  setIsFAQDesktopOpen: Dispatch<SetStateAction<boolean>>;
  setIsFAQMobileOpen: Dispatch<SetStateAction<boolean>>;
  setIsLeftContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setIsRightContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setSelectedInquiry: Dispatch<SetStateAction<InquirySearchType | null>>;
  setInitialInquiryId: Dispatch<SetStateAction<string>>;
  onClickContactSupport: () => void;
}

function DashboardLayout({
  LeftContainer,
  RightContainer,
  MainContainer,
  DrawerFAQContainer,
  DashboardMobileManager,
  showFAQButton,
  isFAQMobileOpen,
  isFAQDesktopOpen,
  isInquirySelected,
  isLeftContainerCollapsed,
  isRightContainerCollapsed,
  setSelectedInquiry,
  setInitialInquiryId,
  setIsFAQDesktopOpen,
  setIsFAQMobileOpen,
  setIsLeftContainerCollapsed,
  setIsRightContainerCollapsed,
  onClickContactSupport
}: DashboardLayoutProps) {
  //state
  const [delayForSidebars, setDelayForSidebars] = useState(0);
  const [delayForFAQ, setDelayForFAQ] = useState(300);
  const [leftSidebarClasses, setLeftSidebarClasses] = useState([styles.detailInnerLeft]);
  const [rightSidebarClasses, setRightSidebarClasses] = useState([styles.detailInnerRight]);
  const [mainContainerClasses, setMainContainerClasses] = useState([styles.mainContainerInner]);
  const [drawerFAQContainerClasses, setDrawerFAQContainerClasses] = useState([styles.drawerFAQContainer]);
  const [dialogContentType, setDialogContentType] = useState(
    DashboardMobileDialogContentTypes.RenderRightSidebarContainer
  );
  const [isDialogMobileOpen, setIsDialogMobileOpen] = useState(false);

  //hooks
  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointUp("md");

  //memos
  const isLeftSidebarVisible = useMemo(() => {
    return isDesktop || !isInquirySelected;
  }, [isDesktop, isInquirySelected]);

  const isMainContainerVisible = useMemo(() => {
    return isDesktop || isInquirySelected;
  }, [isDesktop, isInquirySelected]);

  const detailsContainerClasses = useMemo(() => {
    return cn(styles.detailContainer, { [styles.withFAQ]: isFAQDesktopOpen });
  }, [isFAQDesktopOpen]);

  const isRightSidebarVisible = isDesktop;
  const isFaqContainerVisible = isDesktop;

  const onRightSidebarHandlerOpen = () => {
    setIsDialogMobileOpen(true);
    setDialogContentType(
      DashboardMobileDialogContentTypes.RenderRightSidebarContainer
    );
  };

  const onMobileDialogManagerOpen = () => {
    setIsDialogMobileOpen(true);
    setDialogContentType(
      DashboardMobileDialogContentTypes.RenderMobileDialogManager
    );
  };

  const onMobileDialogManagerClose = () => {
    setIsDialogMobileOpen(false);
  };

  const onFAQSectionHandlerClose = () => {
    setIsFAQDesktopOpen(false);
    setDelayForSidebars(300);
    setDelayForFAQ(0);
    isLeftContainerCollapsed &&
      setIsLeftContainerCollapsed(!isLeftContainerCollapsed);
    isRightContainerCollapsed &&
      setIsRightContainerCollapsed(!isRightContainerCollapsed);
  };

  const onFAQSectionHandlerOpen = () => {
    setIsFAQDesktopOpen(true);
    setDelayForSidebars(0);
    setDelayForFAQ(300);
  };

  const handleRouteChange = useCallback(() => {
    if (isMainContainerVisible) {
      setIsDialogMobileOpen(false);
      setSelectedInquiry(null);
      isMobile && setInitialInquiryId("");
    }
  }, [isMainContainerVisible, setSelectedInquiry, isMobile, setInitialInquiryId]);

  //scroll to top in mobiles after invoice selection
  useEffect(() => {
    if (isMobile && isMainContainerVisible) {
      window.scrollTo(0, 0);
    }
  }, [isMobile, isMainContainerVisible]);

  //processing previous/next browser button event for mobile layout
  useEffect(() => {
    if (isMobile) {
      if (isMainContainerVisible) {
        window.addEventListener("popstate", handleRouteChange);
      }

      if (isLeftSidebarVisible) {
        return () => {
          window.removeEventListener("popstate", handleRouteChange);
        };
      }
    }
  }, [
    handleRouteChange,
    isMobile,
    isLeftSidebarVisible,
    isMainContainerVisible,
  ]);

  //layout classes for mobile FAQ open/close computation
  useEffect(() => {
    const dialogContentType = isFAQMobileOpen
      ? DashboardMobileDialogContentTypes.RenderFAQContainer
      : DashboardMobileDialogContentTypes.RenderMobileDialogManager;
    setDialogContentType(dialogContentType);
  }, [isFAQMobileOpen]);

  useEffect(() => {
    if (
      dialogContentType !==
      DashboardMobileDialogContentTypes.RenderFAQContainer &&
      isMobile
    ) {
      setIsFAQMobileOpen(false);
    }
  }, [dialogContentType, setIsFAQMobileOpen, isMobile]);

  //layout classes for desktop FAQ open/close computation
  useEffect(() => {
    const timer = setTimeout(() => {
      const classes = isFAQDesktopOpen
        ? [`${styles.drawerFAQContainer} ${styles.drawerFAQContainerOpened}`]
        : [styles.drawerFAQContainer];
      setDrawerFAQContainerClasses(classes);
    }, delayForFAQ);

    return () => clearTimeout(timer);
  }, [isFAQDesktopOpen, delayForFAQ]);

  //layout classes FAQ open/close computation
  useEffect(() => {
    const timer = setTimeout(() => {
      const classesLeftSidebar = isFAQDesktopOpen
        ? [`${styles.detailInnerLeft} ${styles.detailInnerLeftClosed}`]
        : [styles.detailInnerLeft];
      const classesRightSidebar = isFAQDesktopOpen
        ? [`${styles.detailInnerRight} ${styles.detailInnerRightClosed}`]
        : [styles.detailInnerRight];
      const classesMainContainer = isFAQDesktopOpen
        ? [`${styles.mainContainerInner} ${styles.mainContainerFaqOpen}`]
        : [styles.mainContainerInner];
      setMainContainerClasses(classesMainContainer);
      setLeftSidebarClasses(classesLeftSidebar);
      setRightSidebarClasses(classesRightSidebar);
      setDelayForSidebars(0);
    }, delayForSidebars);

    return () => clearTimeout(timer);
  }, [isFAQDesktopOpen, delayForSidebars]);

  //left sidebar collapsed/non-collapsed classes computation
  useEffect(() => {
    const timer = setTimeout(() => {
      const classesLeftSidebar = isLeftContainerCollapsed
        ? [`${styles.detailInnerLeft} ${styles.detailInnerLeftCollapsed}`]
        : [styles.detailInnerLeft];
      setLeftSidebarClasses(classesLeftSidebar);
    }, delayForSidebars);

    return () => clearTimeout(timer);
  }, [delayForSidebars, isLeftContainerCollapsed]);

  //right sidebar collapsed/non-collapsed classes computation
  useEffect(() => {
    const timer = setTimeout(() => {
      const classesRightSidebar = isRightContainerCollapsed
        ? [`${styles.detailInnerRight} ${styles.detailInnerRightCollapsed}`]
        : [styles.detailInnerRight];
      setRightSidebarClasses(classesRightSidebar);
    }, delayForSidebars);

    return () => clearTimeout(timer);
  }, [delayForSidebars, isRightContainerCollapsed]);

  //main section classes computation
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isRightContainerCollapsed && isLeftContainerCollapsed) {
        const classesMainContainer =
          isRightContainerCollapsed && isLeftContainerCollapsed
            ? [
              `${styles.mainContainerInner} ${styles.mainContainerBothSidebarsCollapsed}`,
            ]
            : [styles.mainContainerInner];
        setMainContainerClasses(classesMainContainer);
        return;
      }

      if (isRightContainerCollapsed) {
        const classesMainContainer = isRightContainerCollapsed
          ? [
            `${styles.mainContainerInner} ${styles.mainContainerInnerRightCollapsed}`,
          ]
          : [styles.mainContainerInner];
        setMainContainerClasses(classesMainContainer);
      }

      if (isLeftContainerCollapsed) {
        const classesMainContainer = isLeftContainerCollapsed
          ? [
            `${styles.mainContainerInner} ${styles.mainContainerInnerCollapsed}`,
          ]
          : [styles.mainContainerInner];
        setMainContainerClasses(classesMainContainer);
      }

      if (!isRightContainerCollapsed && !isLeftContainerCollapsed) {
        const classesMainContainer = [styles.mainContainerInner];
        setMainContainerClasses(classesMainContainer);
      }
    }, delayForSidebars);

    return () => clearTimeout(timer);
  }, [isRightContainerCollapsed, isLeftContainerCollapsed, delayForSidebars]);

  return (
    <div className={detailsContainerClasses}>
      <div className={styles.detailInner}>
        <LeftSidebarLayout
          leftSidebarClasses={leftSidebarClasses}
          isLeftSidebarVisible={isLeftSidebarVisible}
          LeftContainer={LeftContainer}
        />
        <MainContainerLayout
          isMobile={isMobile}
          isDialogMobileOpen={isDialogMobileOpen}
          isMainContainerVisible={isMainContainerVisible}
          mainContainerClasses={mainContainerClasses}
          dialogContentType={dialogContentType}
          onRightSidebarHandlerOpen={onRightSidebarHandlerOpen}
          onMobileDialogManagerOpen={onMobileDialogManagerOpen}
          onMobileDialogManagerClose={onMobileDialogManagerClose}
          MainContainer={MainContainer}
          RightContainer={RightContainer}
          DashboardMobileManager={DashboardMobileManager}
          DrawerFAQContainer={DrawerFAQContainer}
        />
        <RightSidebarLayout
          isRightSidebarVisible={isRightSidebarVisible}
          isDesktop={isDesktop}
          rightSidebarClasses={rightSidebarClasses}
          onFAQSectionHandlerOpen={showFAQButton ? onFAQSectionHandlerOpen : undefined}
          RightContainer={RightContainer}
          onClickContactSupport={onClickContactSupport}
        />
        {showFAQButton && (
          <FAQLayout
            isFaqContainerVisible={isFaqContainerVisible}
            drawerFAQContainerClasses={drawerFAQContainerClasses}
            onFAQSectionHandlerClose={onFAQSectionHandlerClose}
            DrawerFAQContainer={DrawerFAQContainer}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardLayout;
