import React, { PropsWithChildren } from "react";
import { Dialog } from "@mui/material";
import { BaseTransition } from "@/src/component/base/BaseTransition";
import { DragBar } from "@/src/component/base/DragBar";
import { DraggablePaper } from "@/src/component/base/DraggablePaper";
import { useDragToClose } from "@/src/hook/useDragToClose";
import styles from "./DragDialog.module.scss";

interface DragDialogProps {
  onClose: () => void;
  isOpen: boolean;
  className?: string;
}

function DragDialog({
  onClose,
  isOpen,
  children,
  className
}: PropsWithChildren<DragDialogProps>) {
  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: onClose,
  });
  return (
    <Dialog
      className={className || styles.dragDialog}
      open={isOpen}
      TransitionComponent={BaseTransition}
      keepMounted
      PaperComponent={(props) => (
        <DraggablePaper
          handleStop={handleDragStop}
          handleDrag={handleDrag}
          dragId={dragId}
          {...props}
        />
      )}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DragBar dragId={dragId} />
      {children}
    </Dialog>
  );
}

export default DragDialog;
